import { useEffect, useState, useRef } from 'react'
import api from '../../configs/api'
import { useTranslation } from 'react-i18next';
import OwlCarousel from 'react-owl-carousel2';
import {
    StyledContainer,
    StyledHeadingTwo,
    StyledPartnersLogos,
    StyledImage,
    StyledImageBox,
} from './style'




const Partners = () => {
    const [partners, setPartners] = useState(null)
    const [isMounted, setisMounted] = useState(false)
    const aceEditorRef = useRef();
    const { t } = useTranslation()
    const options = {
        items: 5,
        nav: true,
        rewind: true,
        dots: false,
        navText: [`<img src="/images/left-arrow.svg" width="50px">`, '<img src="/images/right-arrow.svg" width="50px">'],
        autoplay: false,
        responsive:{
            0:{
                items:1,
                nav:true
            },
            600:{
                items:3,
                nav:false
            },
            1000:{
                items:5,
                nav:true,
                loop:false
            }
        }
    };

    const events = {
        onDragged: (event) => { },
        onChanged: (event) => { }
    };
    useEffect(async () => {
        await fetch(`${api.host}/partners/`)
            .then((res) => res.json())
            .then((data) => setPartners(data));
            setTimeout(()=>{
                setisMounted(true)
            }, 1000)
    }, [])

    let res =         <StyledContainer>
    <StyledHeadingTwo>{t('Partners')}</StyledHeadingTwo>
    {partners && (
        <StyledPartnersLogos>
            <OwlCarousel ref={aceEditorRef} options={options}  >
                {partners.map((partner, index) => (
                    <StyledImageBox key={index}>
                        <StyledImage
                            src={partner.logo}
                            alt={partner.name}
                            key={index.toString()}
                        />
                    </StyledImageBox>
                ))}
            </OwlCarousel>
        </StyledPartnersLogos>
    )}
</StyledContainer> 
    return isMounted ? res :  <StyledContainer></StyledContainer>
}

export default Partners
