import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from '../locales/en/translation.json';
import ru from '../locales/ru/translation.json';

// the translations
const resources = {
    en: {
        translation: en
    },
    ru: {
        translation: ru
    }
};

i18n.use(initReactI18next)
    .init({
        resources,
        lng: "ru",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;