import styled from 'styled-components'
import HeadingTwo from '../HeadingTwo'

export const StyledContainer = styled.div`
    background-color: white;
    width: calc(1005 - 48px);
    margin: 0 auto;
    overflow: hidden;

    @media only screen and (max-width: 816px) {
        width: calc(100% - 32px);
    }
`

export const StyledHeadingTwo = styled(HeadingTwo)`
    margin-bottom: 40px;
`

export const StyledPartnersLogos = styled.div`
width:100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;
    
    // column-gap: 24px;

    @media only screen and (max-width: 816px) {
        column-gap: 16px;
    }
`
export const StyledImageBox = styled.div`
    // width: calc(100vw / 7);
    // height: calc(100vw / 7);
    width:100%;
    height: 250px;
    display:flex;
    align-items:center;
    justify-content: center;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
    overflow: hidden;
    // @media only screen and (max-width: 1302px) {
    //     width: calc((100vw - 24px * 6) / 5);
    // }

    // @media only screen and (max-width: 816px) {
    //     width: calc((100vw - 16px * 2));
    // }
`
export const StyledImage = styled.img`
    width: auto;
    height: auto;
    max-width: 60%;
    max-height:60%;
    @media only screen and (max-width: 1302px) {
        width: calc((100vw - 24px * 6) / 5);
    }

    @media only screen and (max-width: 816px) {
        width: calc((100vw - 16px * 2));
    }
`
// export const StyledImage = styled.img`
//     width: calc((100vw - 24px * 10) / 9);
//     height: auto;

//     @media only screen and (max-width: 1302px) {
//         width: calc((100vw - 24px * 6) / 5);
//     }

//     @media only screen and (max-width: 816px) {
//         width: calc((100vw - 16px * 2));
//     }
// `
